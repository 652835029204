import { atom } from '~/observers/jotai'

import { PrintOptions } from './types'

export const printOptionsAtom = atom<PrintOptions>({
  cover: false,
  anamnesis: false,
  parQ: false,
  anthropometricMeasures: false,
  coronarianRisk: false,
  corporalComposition: false,
  hemodynamicMeasures: false,
  scheduling: false,
  aerobicExercisesPrescription: {
    self: false,
    startSession: 1,
    endSession: 112,
  },
  exercisesPrescription: {
    self: false,
    startSession: 1,
    endSession: 54,
  },
})
