import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiX } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'
import zod from 'zod'

import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import { numberMask } from '~/components/TextInput/utils/mask'
import { evaluationAtom } from '~/modules/evalutation/data'
import { printOptionsAtom } from '~/modules/print/data'
import {
  aerobicMacrocycleAtom,
  strengthMacrocycleAtom,
} from '~/modules/standardizedSeries/data'
import { getPrintPagesRoute } from '~/routes/routes'

const schema = zod.object({
  cover: zod.boolean().default(false),
  anamnesis: zod.boolean().default(false),
  parQ: zod.boolean().default(false),
  anthropometricMeasures: zod.boolean().default(false),
  corporalComposition: zod.boolean().default(false),
  coronarianRisk: zod.boolean().default(false),
  hemodynamicMeasures: zod.boolean().default(false),
  scheduling: zod.boolean().default(false),
  aerobicExercisesPrescription: zod.object({
    self: zod.boolean().default(false),
    startSession: zod.number().min(0).max(112),
    endSession: zod.number().min(0).max(112),
  }),
  exercisesPrescription: zod.object({
    self: zod.boolean().default(false),
    startSession: zod.number().min(0).max(112),
    endSession: zod.number().min(0).max(112),
  }),
})

type FormType = zod.infer<typeof schema>
type RouteParams = {
  student_id: string
  evaluation_id: string
}

export const ReportPrinter: React.FC = () => {
  const { student_id, evaluation_id } = useParams<RouteParams>()
  const linkRef = useRef<HTMLAnchorElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { handleSubmit, register, setValue, control } = useForm<FormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...printOptionsAtom.get(),
    },
  })

  const handleCheckAll = useCallback(() => {
    setValue('cover', true)
    setValue('anamnesis', true)
    setValue('anthropometricMeasures', true)
    setValue('parQ', true)
    setValue('corporalComposition', true)
    setValue('coronarianRisk', true)
    setValue('hemodynamicMeasures', true)
    setValue('scheduling', true)
    setValue('aerobicExercisesPrescription.self', true)
    setValue('exercisesPrescription.self', true)
  }, [setValue])

  const handleToggleModal = useCallback(() => {
    setIsOpen((state) => !state)
  }, [])

  const onSubmit = useCallback((payload: FormType) => {
    localStorage.setItem('@GPA/printOptions', JSON.stringify(payload))
    localStorage.setItem(
      '@GPA/printEvaluation',
      JSON.stringify({
        ...evaluationAtom.get(),
        strengthMacrocycle: strengthMacrocycleAtom.get(),
        aerobicMacrocycle: aerobicMacrocycleAtom.get(),
      }),
    )
    linkRef.current?.click()
  }, [])

  return (
    <>
      <Button onClick={handleToggleModal}>Gerar programa</Button>
      <Modal visible={isOpen}>
        <div className='flex flex-col gap-6'>
          <div className='flex items-center justify-between'>
            <h2 className='text-xl font-medium truncate'>Laudo</h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1.5 rounded-full'
              onClick={handleToggleModal}
            >
              <FiX size={24} />
            </button>
          </div>

          <p>Selecione os módulos que serão impressos</p>

          <Button onClick={handleCheckAll}>Marcar todos os módulos</Button>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col gap-4 bg-blue-50 p-4 rounded-lg'
          >
            <Checkbox label='Capa' {...register('cover')} />
            <Checkbox label='Anamnese' {...register('anamnesis')} />
            <Checkbox label='Par-Q' {...register('parQ')} />
            <Checkbox
              label='Medidas Antropométricas'
              {...register('anthropometricMeasures')}
            />
            <Checkbox
              label='Análise da Composição Corporal'
              {...register('corporalComposition')}
            />
            <Checkbox
              label='IMC, IRCQ, Risco Coronariano e Stress'
              {...register('coronarianRisk')}
            />
            <Checkbox
              label='Medidas Hemodinâmicas e Capacidade Aeróbica'
              {...register('hemodynamicMeasures')}
            />
            <Checkbox label='Agendamento' {...register('scheduling')} />

            <div className='relative border border-gpa-blue-500 p-3 pt-7 rounded-md mt-4'>
              <div className='absolute -top-4 -left-0.5 bg-blue-50 pr-3'>
                <Controller
                  control={control}
                  name='aerobicExercisesPrescription.self'
                  render={({ field }) => (
                    <Checkbox
                      label='Prescrição Aeróbica'
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div className='flex gap-3'>
                <Controller
                  control={control}
                  name='aerobicExercisesPrescription.startSession'
                  render={({ field }) => (
                    <TextInput
                      mode='horizontal'
                      label='De'
                      containerClassName='bg-white'
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(+numberMask(e.target.value))
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='aerobicExercisesPrescription.endSession'
                  render={({ field }) => (
                    <TextInput
                      mode='horizontal'
                      label='Até'
                      containerClassName='bg-white'
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(+numberMask(e.target.value))
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className='relative border border-gpa-blue-500 p-3 pt-7 rounded-md mt-4'>
              <div className='absolute -top-4 -left-0.5 bg-blue-50 pr-3'>
                <Controller
                  control={control}
                  name='exercisesPrescription.self'
                  render={({ field }) => (
                    <Checkbox
                      label='Prescrição de Exercícios (Série)'
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div className='flex gap-3'>
                <Controller
                  control={control}
                  name='exercisesPrescription.startSession'
                  render={({ field }) => (
                    <TextInput
                      mode='horizontal'
                      label='De'
                      containerClassName='bg-white'
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(+numberMask(e.target.value))
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='exercisesPrescription.endSession'
                  render={({ field }) => (
                    <TextInput
                      mode='horizontal'
                      label='Até'
                      containerClassName='bg-white'
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(+numberMask(e.target.value))
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <p>
                <b>Atenção!</b> <br />
                Verifique o número de séries antes de imprir para que não
                consuma páginas além do necessário.
              </p>
            </div>
            <Button className='self-end'>Gerar programa</Button>
          </form>
          <Link
            ref={linkRef}
            to={{ pathname: getPrintPagesRoute(student_id, evaluation_id) }}
            target='_blank'
            rel='noopener noreferrer'
            className='hidden'
          />
        </div>
      </Modal>
    </>
  )
}
