import { useCallback, useContext, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluation } from '~/modules/evalutation/services'
import { useAtom } from '~/observers/jotai'
import {
  exercisesPrescriptionsRoute,
  studentEvaluationsRoute,
} from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import { EditModal } from './compoenents/EditModal'

export const SeriesEdit: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [evaluation] = useAtom(evaluationAtom)

  const [loading, setLoading] = useState<boolean>(false)

  const handleSave = useCallback(async () => {
    if (!evaluation_id || !student_id) return
    setLoading(true)

    await patchEvaluation(student_id, evaluation_id, {
      periodizedSeries: evaluationAtom.get()?.periodizedSeries,
    })

    setLoading(false)
    navigate({
      pathname:
        studentEvaluationsRoute
          .replace(':evaluation_id', evaluation_id)
          .replace(':student_id', student_id) + '/macrociclos',
    })
  }, [evaluation_id, student_id, navigate])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      exercisesPrescriptionsRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      {!evaluation?.periodizedSeries?.length ? (
        <p className='px-4 py-2 bg-red-100 rounded-lg'>
          Nenhuma série iniciada. Volte para o item 7 (Agendamento) e selecione
          uma quantidade de séries dentro do campo &quot;Exercícios de
          Força&quot;.{' '}
          <b>
            Caso não seja de seu interesse iniciar uma série, você pode ignorar
            esta etapa clicando diretamente em &quot;Salvar e ir para plano de
            treino&quot;
          </b>
        </p>
      ) : null}
      {evaluation?.periodizedSeries.map((series) => (
        <div
          key={`data-test-${series.letter}`}
          className='flex flex-col gap-4 border border-slate-200 rounded-xl p-4'
        >
          <div className='flex flex-wrap gap-4'>
            <div className='flex flex-col shrink-0'>
              <h2 className='text-xl'>Série {series.letter}</h2>
              <p>Descrição: {series.description || '*sem descrição*'}</p>
            </div>
          </div>
          <div className='flex flex-col gap-6'>
            {series.exercises.map((exercise, index) => (
              <div
                key={`series-${series.letter}-${index}`}
                className='flex flex-col bg-gpa-blue-50 p-4 rounded-lg border border-slate-400'
              >
                <div className=''>
                  <span className='text-lg font-semibold'>{exercise.name}</span>
                </div>
                <div className='flex flex-col gap-4'>
                  <div className='flex flex-wrap gap-3'>
                    <span>Carga Inicial {exercise.initialCharge}</span>
                    <span>Carga Máxima {exercise.maximumCharge}</span>
                    <span>Séries {exercise.numSeries}</span>
                    <span>Repetições {exercise.repetitions}</span>
                    <span>Intervalo {exercise.interval}</span>
                    <span>Velocidade {exercise.speed}</span>
                    <span>Ajuste/Obs {exercise.adjustments}</span>
                  </div>

                  <div className='flex flex-wrap gap-3 self-end'>
                    <EditModal series={series} exercise={exercise} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className='flex justify-between w-full'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Salvar e ir para plano de treino</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
