import { useCallback, useContext, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  patchEvaluation,
  validateIntensityRange,
} from '~/modules/evalutation/services'
import { insertToast } from '~/modules/toasts/services'
import { useAtom } from '~/observers/jotai'
import { aerobicExercisesRoute, standardizedSeriesRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import { StrengthExercises } from './components/Exercises'
import { Series } from './components/Series'
import { Standardized } from './components/Standardized'

type TabValues = 'exercise' | 'standardized'

export const ExercisesPrescription: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [evaluation] = useAtom(evaluationAtom)
  const [tab, setTab] = useState<TabValues>('exercise')
  const [loading, setLoading] = useState<boolean>(false)

  const handleSave = useCallback(async () => {
    if (!student_id || !evaluation_id) return

    const periodizedSeries = evaluationAtom.get()?.periodizedSeries ?? []
    const invalid = periodizedSeries.some((ps) => !validateIntensityRange(ps))

    if (invalid) {
      insertToast({
        duration: 7,
        message:
          'Verifique se o valor da intensidade está dentro do limite correto.',
        title: 'Formato inválido',
        type: 'warn',
      })
      return
    }

    setLoading(true)

    patchEvaluation(student_id, evaluation_id, {
      periodizedSeries,
    })
      .then((data) => {
        if (data) {
          scrollToTop()
          navigate(
            standardizedSeriesRoute
              .replace(':student_id', student_id)
              .replace(':evaluation_id', evaluation_id),
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [student_id, evaluation_id, navigate, scrollToTop])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      aerobicExercisesRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <div className='px-4 py-2 bg-gpa-blue-50 rounded-lg'>
        <h2 className='text-xl font-semibold'>Sua listagem de exercícios</h2>
        <p>
          Aqui você pode adicionar novos exercícios e criar séries padronizadas.
        </p>
      </div>
      <div className='flex flex-col gap-6 border-2 rounded-lg p-2'>
        <div className='flex gap-4'>
          <button
            data-active={tab === 'exercise'}
            className='data-[active=true]:font-semibold data-[active=true]:bg-gpa-blue-50 px-2 py-1 rounded-lg'
            onClick={() => setTab('exercise')}
          >
            Exercícios
          </button>
          <button
            data-active={tab === 'standardized'}
            className='data-[active=true]:font-semibold data-[active=true]:bg-gpa-blue-50 px-2 py-1 rounded-lg'
            onClick={() => setTab('standardized')}
          >
            Séries Padronizadas
          </button>
        </div>
        {tab === 'exercise' ? <StrengthExercises /> : <Standardized />}
      </div>

      <div className='flex flex-col gap-4'>
        <div className='px-4 py-2 bg-gpa-blue-50 rounded-lg'>
          <h2 className='text-xl font-semibold'>Séries Padronizadas</h2>
          <p>
            Aqui você edita as séries do plano de treino. (seleciona e move
            exercícios para cada série)
          </p>
        </div>
        {evaluation?.periodizedSeries?.length ? null : (
          <p className='px-4 py-2 bg-red-100 rounded-lg'>
            Nenhuma série iniciada. Volte para o item 7 (Agendamento) e
            selecione uma quantidade de séries dentro do campo &quot;Exercícios
            de Força&quot;.{' '}
            <b>
              Caso não seja de seu interesse iniciar uma série, você pode
              ignorar esta etapa clicando diretamente em &quot;Salvar&quot;
            </b>
          </p>
        )}
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
          {evaluation?.periodizedSeries.map((series, index) => (
            <Series
              key={`${series.letter}-${index}`}
              data={series}
              index={index}
            />
          ))}
        </div>
      </div>
      <div className='flex justify-between w-full'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
