/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState } from 'react'
import { FiX, FiZoomIn, FiZoomOut } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'
import { PrintOptions } from '~/modules/print/types'
import {
  aerobicMacrocycleAtom,
  strengthMacrocycleAtom,
} from '~/modules/standardizedSeries/data'

import { AerobicExercisesPrescription } from './AerobicExercisesPrescription'
import { Anamnesis } from './Anamnesis'
import { AntropometricMeasures } from './AntropometricMeasures'
import { CoronarianRisk } from './CoronarianRisk'
import { CorporalCompositionAnalysis } from './CorporalCompositionAnalysis'
import { Cover } from './Cover'
import { ExercisePrescription } from './ExercisesPrescription'
import { ParQ } from './ParQ'
import { Scheduling } from './Scheduling'

export const PrintPages: React.FC = () => {
  const [data, setData] = useState<PrintOptions>()
  const zoomAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const storage = localStorage.getItem('@GPA/printOptions')
    const storedEvaluation = localStorage.getItem('@GPA/printEvaluation')
    storage && setData(JSON.parse(storage))
    if (storedEvaluation) {
      const parsedData = JSON.parse(storedEvaluation)
      evaluationReadOnlyAtom.set(parsedData)
      aerobicMacrocycleAtom.set(parsedData.aerobicMacrocycle)
      strengthMacrocycleAtom.set(parsedData.strengthMacrocycle)
    }
    return () => {
      localStorage.removeItem('@GPA/printOptions')
      localStorage.removeItem('@GPA/printEvaluation')
    }
  }, [])

  console.log(data?.exercisesPrescription)

  return (
    <div className='relative bg-white block'>
      <div className='fixed print:hidden z-20 flex items-center justify-between top-0 left-0 right-0 px-5 py-3 shadow-md bg-white'>
        <b>Revise o conteúdo a ser impresso</b>

        <div className='flex justify-center items-center gap-5'>
          <div
            role='button'
            className='p-3 bg-slate-200 rounded-md'
            onClick={() => {
              // @ts-ignore: Ignore TypeScript error for non-standard CSS property 'zoom'
              const currentZoom = +getComputedStyle(zoomAreaRef.current).zoom
              if (currentZoom <= 1) return
              // @ts-ignore: Ignore TypeScript error for non-standard CSS property 'zoom'
              zoomAreaRef.current.style.zoom = currentZoom - 0.1 + ''
            }}
          >
            <FiZoomOut />
          </div>
          <div
            role='button'
            className='p-3 bg-slate-200 rounded-md'
            onClick={() => {
              // @ts-ignore: Ignore TypeScript error for non-standard CSS property 'zoom'
              const currentZoom = +getComputedStyle(zoomAreaRef.current).zoom
              // @ts-ignore: Ignore TypeScript error for non-standard CSS property 'zoom'
              zoomAreaRef.current.style.zoom = currentZoom + 0.1 + ''
            }}
          >
            <FiZoomIn />
          </div>
          <Button
            onClick={() => {
              // @ts-ignore: Ignore TypeScript error for non-standard CSS property 'zoom'
              zoomAreaRef.current.style.zoom = 1 + ''
              window.print()
            }}
          >
            Imprimir
          </Button>

          <div className='h-10 w-0.5 bg-slate-200 flex'></div>

          <div
            role='button'
            className='p-3 bg-slate-200 rounded-md'
            onClick={() => {
              window.close()
            }}
          >
            <FiX />
          </div>
        </div>
      </div>
      <div ref={zoomAreaRef}>
        {data?.cover ? <Cover /> : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {data?.anamnesis ? <Anamnesis /> : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {data?.parQ ? <ParQ /> : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {data?.anthropometricMeasures ? <AntropometricMeasures /> : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {data?.corporalComposition ? <CorporalCompositionAnalysis /> : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {data?.coronarianRisk ? <CoronarianRisk /> : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {data?.scheduling ? <Scheduling /> : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {data?.aerobicExercisesPrescription.self ? (
          <AerobicExercisesPrescription
            min={data.aerobicExercisesPrescription.startSession}
            max={data.aerobicExercisesPrescription.endSession}
          />
        ) : null}
        <hr className='bg-black py-0.5 my-5 print:hidden' />
        {!data?.exercisesPrescription.self ||
        !data.exercisesPrescription.startSession ||
        !data.exercisesPrescription.endSession ? null : (
          <ExercisePrescription
            min={data.exercisesPrescription.startSession}
            max={data.exercisesPrescription.endSession}
          />
        )}
      </div>
    </div>
  )
}
